<template>
	<div>
        <LoadingSpinner v-show="loading" class="col-12"/>

        <div v-show="!loading">
            <form @submit="checkForm" id="addTiers">
                <div class="row">
                    <div class="col-12" v-if="form_message !== ''">
                        <ErrorAlert :messageI18n="form_message" />
                    </div>

                    <div class="col-12" >
                        <div class="form-group">
                            <label for="contact_civility" class="col-form-label">{{ $t("contact.civility") }} *</label>
                            <e-select
                                id="contact_civility"
                                v-model="form.contact_civility"
                                :options="[
                                    $t('civility.mr'),
                                    $t('civility.mme')
                                ]"
                                :allow-empty="false"
                                :show-labels="false"
                            />
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="contact_firstname" class="col-form-label">{{ $t("contact.firstname") }}</label>
                                    <input type="text" id="contact_firstname" class="form-control" v-model="form.contact_firstname">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="contact_lastname" class="col-form-label">{{ $t("contact.lastname") }}</label>
                                    <input type="text" id="contact_lastname" class="form-control" v-model="form.contact_lastname">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="contact_address1" class="col-form-label">{{ $t("contact.address1") }}</label>
                            <input type="text" id="contact_address1" class="form-control" v-model="form.contact_address1">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="contact_address2" class="col-form-label">{{ $t("contact.address2") }}</label>
                            <input type="text" id="contact_address2" class="form-control" v-model="form.contact_address2">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="contact_postalcode" class="col-form-label">{{ $t("contact.postalcode") }}</label>
                            <input type="text" id="contact_postalcode" class="form-control" v-model="form.contact_postalcode">
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="form-group">
                            <label for="contact_town" class="col-form-label">{{ $t("contact.town") }}</label>
                            <input type="text" id="contact_town" class="form-control" v-model="form.contact_town">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="contact_country" class="col-form-label">{{ $t("contact.country") }}</label>
                            <CountryInput v-model="form.contact_country"></CountryInput>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="contact_mail" class="col-form-label">{{ $t("contact.email") }}</label>
                            <input type="email" id="contact_mail" class="form-control" v-model="form.contact_mail">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label class="col-form-label">{{ $t("contact.phone") }}</label>
                    </div>

                    <div class="col-12 phone mb-3" v-for="(phone, key_phone) in form.phones" v-bind:key="key_phone">
                        <div class="row no-gutters">
                            <div class="col-auto pr-2">
                                <phoneInput v-model="form.phones[key_phone]"></phoneInput>
                            </div>
                            <div class="col pr-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <b-form-input type="text" class="form-control" style="width: 5em;" v-model="phone.phone_indicatif"/>
                                    </div>
                                    <input type="text" class="form-control" v-model="phone.phone_numero" required>
                                </div>
                            </div>
                            <div class="col-3">
                                <e-select
                                    id="phone_type"
                                    v-model="phone.phone_type"
                                    :options="[
                                        $t('tiers.phone_type.fixe'),
                                        $t('tiers.phone_type.portable')
                                    ]"
                                    :allow-empty="false"
                                    :show-labels="false"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-right">
                        <a href="#" @click.prevent="addPhoneNumber()">{{ $t('global.ajouter_numero_telephone') }} <font-awesome-icon :icon="['fal', 'phone-plus']"/></a>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12" v-if="boolean_fonction">
                        <div class="form-group">
                            <label>{{ $t("contact.fonction_perso") }}</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="fonction_perso[key].name"
                                v-for="(perso, key) in fonction_perso"
                                :key="key"
                            >
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <b-form-checkbox
                                class="custom-control"
                                v-model="form.contact_operateur" 
                                name="check-button" 
                                switch
                            >
                                {{ $t("contact.operateur") }}
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div v-if="form.contact_operateur" class="col-12">
                        <div class="form-group">
                            <label for="contact_num_ordre" class="col-form-label">{{ $t("contact.num_ordre") }}</label>
                            <input type="email" id="contact_num_ordre" class="form-control" v-model="form.contact_num_ordre">
                        </div>
                    </div>
                </div>

            </form>

            <div class="text-center">
                <b-button variant="primary" @click.prevent="checkForm">{{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
            </div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'

	import Countries from '@/assets/lang/countries/countries'
	import Indicatif from '@/assets/lang/countries/indicatif'

	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Contact from '@/mixins/Contact.js'
	import Phone from '@/mixins/Phone.js'
	import Fonction from '@/mixins/Fonction.js'
	import Tiers from '@/mixins/Tiers.js'
	import Horse from "@/mixins/Horse.js"
    import Shutter from "@/mixins/Shutter.js"

	export default {
		name: "AjoutContact",
		mixins: [Navigation, Tools, Contact, Phone, Fonction, Tiers, Horse, Shutter],
        props: ['code_onboarding'],
		data () {
			return {
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				selectedLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				labelFonctionTiersContactVat: this.getTrad("contact.search_fonction"),

				loading: true,
				form_message: "",
				errors: [],
				form: {},
				countries: {},
				country_choosen: false,
				fonctions: [],
				fonctions_formatted_flat: [],
				default_form: {
					contact_firstname: '',
					contact_lastname: '',
					contact_civility: 'Mr',
					contact_mail: '',
					contact_address1: '',
					contact_address2: '',
					contact_postalcode: '',
					contact_town: '',
					contact_country: '75',
					contact_website: '',
					contact_entity: false,
					tierscontact_poste: "",
					tierscontact_favoris: false,
					contact_operateur: true,
					contact_num_ordre: '',
					phones: [
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.fixe'),
							phone_country: "FRA"
						},
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.portable'),
							phone_country: "FRA"
						}
					],
					fonctions_formatted: []
				},
				boolean_fonction: false,
				fonction_perso: [
					{
						name: ""
					}
				],
				contact_existing: [],
				contact_choice: null,
				loadingContact: false
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.form = this.deppCloneObj(this.default_form)
                this.loading = false
			},
			async addOrEditContact(tiers_id, horse_id, contact_id) {
				this.form_message = ""
				this.modal_loaded = false
				this.$refs['modal'].show()
				this.indicatifs = Indicatif
				this.countries = Countries[Vue.i18n.locale()].label
				this.countries_iso = Countries[Vue.i18n.locale()].iso_3
				this.fonction_perso = [{
					name: ""
				}]

				this.boolean_fonction = false
                this.form = this.deppCloneObj(this.$parent.filtredContacts.filter(contact => contact.contact_id == contact_id)[0])
                this.fonctions_formatted_flat = []

				this.country_choosen = true
				this.modal_loaded = true
			},
			async checkForm() {
				this.form_message = ""
				this.errors = []
				if(!this.form.contact_civility) {
					this.errors.push("civility")
				}

				if(this.errors.length == 0) {
					let result = null
					let params_to_send = {
						"contact": this.form,
						"fonction_perso": this.fonction_perso
					}

                    try {
                        await this.addContact(params_to_send.contact)
                    } catch (error) {
                        console.error(error)
                        this.form_message = "error.LEP"
                    }

                    if(this.code_onboarding) {
                        const params =  {
                            code: this.code_onboarding,
                            done: 1,
                            skip: 0
                        }

                        this.ok(params)
                        this.shutterPanel().close(this.code_onboarding)
                    }
                    else {
                        this.ok()
                        this.shutterPanel().close('contact-add-form')
                    }
				} else {
					this.form_message = "formulaire.erreur_champs_non_remplis"
				}
			},
			addPhoneNumber() {
				this.form.phones.push({
					phone_indicatif: '+33',
					phone_numero: '',
					phone_type: this.getTrad('tiers.phone_type.fixe'),
					phone_country: 'FRA',
				})
			},
			indicatif(country, key_phone) {
				this.form.phones[key_phone].phone_indicatif = this.indicatifs[country]
				this.form.phones[key_phone].phone_country = country
			},
			addFonctionInput() {
				if(this.fonction_perso.length >= 1 && this.boolean_fonction) {
					this.fonction_perso.push({name:""})	
				}

				this.boolean_fonction = true
			}
		},
		computed: {
			contact_formatted: function() {
				return this.contact_existing.map(contact => {
					let contact_label = contact.contact_firstname + ' ' + contact.contact_lastname
					if(contact.phones.length > 0) contact_label += ' (' + contact.phones[0].phone_combine + ')'

					return {contact_id: contact.contact_id, contact_label: contact_label}
				})
			}
		},

		watch: {
		    'fonctions_formatted_flat'(val) {
		    	this.form.fonctions_formatted = this.fonctions_formatted_flat
	        }
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
			CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
		}
	}
</script>